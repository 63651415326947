<script lang="ts" setup>
import { createReusableTemplate } from '@vueuse/core'

defineProps<{
  cta?: string
}>()

useHead({
  titleTemplate: '%s %separator Forgd',
  templateParams: {
    separator: '·',
  },
  title: useRoute().meta.title,
})

const [DefineFooter, ReuseFooter] = createReusableTemplate()
</script>

<template>
  <DefineFooter>
    <slot name="footer">
      <!-- Footer call to action, something generic -->
      <AppContact class="!mt-0">
        <template #default>
          {{ cta || $route.meta.cta || 'Contact us for a 1:1 consultation regarding all things related to your project.' }}
        </template>
        <template #cta>
          <UButton
            size="xl"
            color="black"
            class="px-12 py-3"
            to="https://calendly.com/forgd/forgd-consultation"
            target="_blank"
          >
            Schedule a 1:1 Consultation
          </UButton>
        </template>
      </AppContact>
    </slot>
  </DefineFooter>
  <div data-layout="core/dashboard">
    <div
      class="bg-forgd-bgd-200 text-forgd-primary-900 min-h-screen font-normal font-display"
    >
      <div class="flex min-h-screen relative">
        <AppMenuExpandable :expanded="!$slots.sidebar" class="z-5" />
        <div class="flex flex-col flex-grow">
          <!-- page header -->
          <div
            class="h-[80px] px-5 flex justify-between items-center border-b border-forgd-bgd-600"
          >
            <slot name="header">
              <div class="flex gap-4 items-center">
                <div class="flex gap-4 items-center">
                  <img v-if="$route.meta.icon && $route.meta.icon.endsWith('.svg')" :src="$route.meta.icon" class="h-[30px]">
                  <div class="text-xl font-semibold flex-shrink-0">
                    {{ $route.meta.title }}
                  </div>
                </div>
              </div>
            </slot>

            <div class="flex-grow-0">
              <UiUserProjectsDropDown />
            </div>
          </div>

          <div class="flex w-full">
            <div v-if="$slots.sidebar">
              <slot name="sidebar" />
            </div>
            <div class="flex-grow w-full">
              <div class="p-10  mx-auto flex-grow space-y-10 max-w-[1200px]">
                <slot />
              </div>
              <div class="max-w-[1200px] mx-auto border-t border-forgd-gray-300">
                <ReuseFooter v-if="$slots.sidebar" />
              </div>
            </div>
          </div>
          <div v-if="!$slots.sidebar" class="max-w-[1200px] mx-auto border-t border-forgd-gray-300">
            <ReuseFooter />
          </div>
          <AppFooter />
        </div>
      </div>
    </div>
    <UNotifications />
    <USlideovers />
  </div>
</template>
